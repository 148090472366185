// DEDICATED STYLES
// --------------------------------------------------

html, body, #root {
  height: 100%;
  width: 100%;
  color: $dark;
  overflow: hidden;
  font-family: $font-family-base;
}

.global {
  width: 100%;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;

}

.global-container {
  min-height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 0;
  grid-template-areas:
    "Header Header"
    "Menu Main"
    "Footer Footer";
}

.main {
  grid-area: Main;
  position: relative;
  display: flex;
  flex: 1 1 100%;
  z-index: 1;
  background-color: $bg-light;
  min-width: 0;
  height: 100vh;
  overflow-y: auto;

  @include media-breakpoint-up(sm) {
    flex-direction: column;
  }

  .app-content {
    flex: 1;
    background-color: $bg-light;
    min-height: 100vh;
    width: 100%;
  }
}
