.epow-order-item-preview {
  background-color: $white;

  &.no-supplier {
    border: 2px solid $warning;
  }
}

.is-overbooked {
  border: 2px solid $warning;
}
