.epow-content {
  @include media-breakpoint-up(lg) {
    margin-left: 250px;
  }

  & .epow-content-body {
      padding: 20px 40px;
  }

}

.back-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}
