.epow-custom-table.invoice-payment-row {
  background-color: #dae6f5;


  .invoice-payment-item-row-wrapper {
    background-color: #f9fbfc;

  }

  .invoice-payment-item-row {
    background-color: $white;
  }
}
