$resource-calendar-gap: 0.75rem;
$resource-calendar-cell-height: 6rem;
$resource-calendar-cell-width: 9rem;
$resource-calendar-resource-cell-height: 2rem;

$calendar-green: #D9FCD6;
$calendar-blue: #DBE7F4;
$calendar-red: #FAE2E3;
$calendar-orange: #fde0ce;
$calendar-green-icon: #20CF31;

.resource-calendar-filter {
    .epow-filter {
        *[id$="-listbox"] {
            z-index: 4;
        }
    }
}

.resource-calendar {
    display: flex;
    gap: 0.5rem;
    height: 70vh;
    overflow-x: scroll;
    align-items: flex-start;

    .resource-calendar-column {
        &:first-of-type {
            // for activities, skip the resource row
            margin-top: $resource-calendar-gap + $resource-calendar-resource-cell-height;
            position: sticky;
            left: 0;
            z-index: 2;
        }

        .resource-calendar-resource-cell,
        .resource-calendar-activity-cell,
        .resource-calendar-cell {
            display: flex;
            min-width: $resource-calendar-cell-width;
            width: $resource-calendar-cell-width;
            height: $resource-calendar-cell-height;
            margin-bottom: $resource-calendar-gap;

            border: 1px solid $gray;
            background-color: $white;
            padding: 10px;
        }

        .resource-calendar-resource-cell {
            height: $resource-calendar-resource-cell-height;
            align-items: center;
            justify-content: center;
            font-weight: bolder;
            font-size: 14px;
            cursor: pointer;
            position: sticky;
            top: 0;
        }

        .resource-calendar-activity-cell {
            min-width: 16rem;
            width: 16rem;
            white-space: nowrap;
            cursor: pointer;
            flex-direction: column;

            >div {
                align-items: center;
            }

            .activity-cell-text {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .resource-calendar-cell {
            display: flex;
            flex-direction: column;
            border-color: $gray;
            padding: 10px;

            .icon-group {
                display: flex;
                margin-bottom: 1rem;
                justify-content: space-between;

                .epow-icon {
                    margin: 0;
                }
            }

            &.cell-inline {
                flex-direction: row;
                align-items: center;

                .icon-group {
                    margin-bottom: 0;

                    .epow-icon {
                        margin-bottom: 0;
                        margin-right: 0.75rem;
                    }
                }
            }

            &.background-SENT {
                background-color: $bg-light;

                & .cell-icon {
                    color: $secondary;
                }
            }

          &.background-REFUSED {
            background-color: $calendar-orange;

            & .cell-icon {
              color: $danger;
            }
          }

            &.background-AVAILABLE {
                background-color: $white;

                & .cell-icon {
                    color: $calendar-green-icon;
                }
            }

            &.background-CONFIRMED {
                background-color: $calendar-green;

                & .cell-icon {
                    color: $calendar-green-icon;
                }
            }

            &.background-UNAVAILABLE {
                background-color: $calendar-red;

                & .cell-icon {
                    color: $danger;
                }
            }

            &.background-ALREADY_STAFFED {
                background-color: $calendar-orange;

                & .cell-icon {
                    color: $warning;
                }
            }

            &.background-EMPTY {
                background-color: $gray;
            }

            &.background-FREE {
                background-color: $calendar-blue;

                & .cell-icon {
                    color: $secondary;
                }
            }
        }

    }



}
