.epow-custom-table.activity-billing-overview {
  display: flex;
  flex-direction: column;

  .epow-custom-table-header-row.activity-billing-overview {
    display: flex;

    .epow-custom-table-header.activity-billing-overview {
      display: flex;
      padding: 0.5rem 0;

      >span {
        font-weight: bold;
        margin-right: 0.5rem;
      }

      &:last-child>span {
        margin-left: auto;
      }
    }
  }

  .epow-custom-table-row.activity-billing-overview {
    flex-direction: column;
    margin-bottom: 0.5rem;

    .activity-billing-overview-row {
      background-color: $white;
      vertical-align: middle;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      cursor: pointer;

      div {
        display: flex;
        align-items: center;
      }
    }
  }

  .epow-custom-table-row-details {
    background-color: $white;
    padding-bottom: 0.25rem;
    border-top: 1px solid $light;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .details-header {
      font-weight: bold;
    }

    .row {
      margin-left: 15px;
      margin-bottom: 0.5rem;
    }

  }

}

.scroller {
  overflow: auto;
  height: calc(100vh - 295px);
  display: flex;
  flex-direction: column-reverse;

  .scroller-content {
    .item {
      transform: translateZ(0);

      .date {
        max-width: 85%;
      }

      /* Style de base pour la bulle de commentaire */
      .comment-bubble {
        background-color: #f1f1f1;
        padding: 10px;
        border-radius: 8px;
        max-width: 85%;
        position: relative;
        text-align: left;
      }
    }

    .comment-owner {
      text-align: -webkit-right;
      .comment-bubble {
        background-color: #d3f6cc;
      }
    }
  }
}
