.epow-home {
  img {
    max-width: 600px;
  }
}

.epow-dashboard-right {
  @include media-breakpoint-up(lg) {
    max-width: 300px;
  }

}

.epow-banner {
    height: 71px;
    width: 100%;
    background: #081520;
}

.epow-dashboard-next-tasks {

  .next-task-wrapper{
    display: flex;

    .next-task {
      flex: 1 1 0;
      min-width: 150px;
      max-width: 150px;
    }

    .next-task-200 {
      flex: 1 1 0;
      min-width: 200px;
      max-width: 200px;
    }
  }

}

